import { createContext, useContext } from 'react';
import { ComponentRenderData } from '@plasmicapp/loader-react';

export type PlasmicContextProps = {
  enabled: boolean;
  prefetchedData: ComponentRenderData;
  pageRoute: string;
  globalContextsProps?: Record<string, any>;
  componentDisplayName: string;
  variation?: Record<string, string>;
  externalIds?: Record<string, string>;
};

const PlasmicContext = createContext<PlasmicContextProps>(
  {} as PlasmicContextProps
);

export const usePlasmicContext = () => {
  const context = useContext(PlasmicContext);

  if (!context) {
    throw new Error('Context used outside of its Provider!');
  }

  return context;
};

interface PlasmicProvider {
  plasmic: PlasmicContextProps;
  children: React.ReactNode;
}

export const PlasmicProvider: React.FC<PlasmicProvider> = ({
  plasmic,
  children,
}) => {
  return (
    <PlasmicContext.Provider value={plasmic}>
      {children}
    </PlasmicContext.Provider>
  );
};
