import uniqueId from 'lodash/uniqueId';
import { JsonSchema } from '~/types/Magnolia';
import { stringSanitizer } from '~/utils/strings';
import { JsonLd, JsonLdProps } from '@components/JsonLd/JsonLd';

export interface JsonLdNextSeoProps extends JsonLdProps {
  json?: JsonSchema[];
}

export function JsonLdNextSeo({ json }: JsonLdNextSeoProps) {
  const getKey = (camel) => {
    const key = camel?.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
    return uniqueId(`${key}-scheme_`);
  };

  const getObject = (data) => {
    if (data) {
      try {
        const dataJsonPrepare = stringSanitizer(data);
        return JSON.parse(dataJsonPrepare);
      } catch {
        console.error(
          'Invalid DATA: please check your data is a properly formatted JSON Array',
          data
        );
      }
    }
  };

  return (
    <>
      {json?.map((item) => {
        return (
          <JsonLd
            key={getKey(item.name)}
            type={item.name}
            scriptKey={getKey(item.name)}
            dataArray={getObject(item.object)}
          />
        );
      })}
    </>
  );
}
