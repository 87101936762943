import { JsonLdLocalBusinessProviderAndReviews } from '@components/JsonLd/JsonLdLocalBusinessProviderAndReviews';
import { JsonLdNextSeo } from '@components/JsonLd/JsonLdNextSeo';
import config from '@components/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import ProviderContext from '~/contexts/Provider';
import { PageProps } from '~/types/Magnolia';
import { EditablePage, EditablePageProps, PageContext } from './EditablePage';
import {
  PlasmicRootProvider,
  PlasmicComponent,
} from '@plasmicapp/loader-nextjs';
import {
  PlasmicContextProps,
  usePlasmicContext,
} from '~/contexts/PlasmicContext';

import { PLASMIC } from '@lib/plasmic';

const NextSeo = dynamic(() => import('@components/NextSeo'));

const MagnoliaPageContainer: React.FC<EditablePageProps> = ({
  content,
  templateAnnotations,
}) => {
  const router = useRouter();

  // Temp fix for the green bar issue: MGNLFE-633 - https://jira.magnolia-cms.com/browse/MGNLFE-633
  useEffect(() => {
    if (router.isPreview) {
      const checkMgnlEditorBar = () => {
        const mgnlEditorBar = document.querySelector('.mgnlEditorBar');
        if (mgnlEditorBar) {
          clearInterval(intervalId);
        } else {
          if (window.mgnlFrameReady) window.mgnlFrameReady();
        }
      };
      const intervalId = setInterval(checkMgnlEditorBar, 200);
    }
  }, [router.isPreview]);

  return (
    <EditablePage
      content={content}
      config={config}
      templateAnnotations={templateAnnotations}
    />
  );
};

const PlasmicPageContainer: React.FC<PlasmicContextProps> = (plasmic) => {
  return (
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmic.prefetchedData}
      pageRoute={plasmic.pageRoute}
      globalContextsProps={plasmic.globalContextsProps}
      variation={plasmic.variation}
      disableLoadingBoundary={true}
      disableRootLoadingBoundary={true}
    >
      <PlasmicComponent component={plasmic.componentDisplayName} />
    </PlasmicRootProvider>
  );
};

type PageContainerProps = Omit<
  PageProps,
  | 'error'
  | 'pagePath'
  | 'redirect'
  | 'catalog'
  | 'provider'
  | 'story'
  | 'plasmic'
>;

const PageContainer: React.FC<PageContainerProps> = ({
  page,
  templateAnnotations,
  context,
}) => {
  const plasmic = usePlasmicContext();

  const provider = useContext(ProviderContext)?.provider;

  const pageContext: PageContainerProps = {
    page: page,
    templateAnnotations: templateAnnotations,
    context: context,
  };

  // See CME-1383 - https://caring.atlassian.net/browse/CME-1383
  const schemas = page.jsonSchemas || [];

  if (schemas) {
    Object.keys(schemas).forEach((schema) => {
      schemas[schema] = JSON.parse(
        JSON.stringify(schemas[schema]).replace(
          '[search_term_string]',
          '{search_term_string}'
        )
      );
    });
  }

  const template = plasmic.enabled ? (
    <PlasmicPageContainer {...plasmic} />
  ) : (
    <MagnoliaPageContainer
      content={page}
      config={config}
      templateAnnotations={templateAnnotations}
    />
  );

  return (
    <>
      <PageContext.Provider value={pageContext}>
        <JsonLdNextSeo json={schemas} />
        <NextSeo
          title={page.metaTitle}
          description={page.metaDescription}
          keywords={page.metaKeywords}
          openGraph={page.openGraph}
          canonical={page.canonical}
          noindex={page.noindex}
          nofollow={page.nofollow}
        />
        {provider && (
          <JsonLdLocalBusinessProviderAndReviews
            canonical={page.canonical}
            provider={provider}
            context={context}
          />
        )}
        {template}
      </PageContext.Provider>
    </>
  );
};

export default PageContainer;
