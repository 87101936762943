import dynamic from 'next/dynamic';
import AdvertisingDisclosure from './AdvertisingDisclosure';
import { NewCallToAction } from './NewCallToAction';
import CallToActionBlocks from './NewCallToAction/CallToActionBlocks';
import ReviewSubmissionsNew from './ReviewForms/ReviewSubmissionsNew';

// Pages
const DefaultPage = dynamic(() => import('@components/pages/DefaultPage'));
const GeoPage = dynamic(() => import('@components/pages/GeoPage'));
const OneColumnPage = dynamic(() => import('@components/pages/OneColumnPage'));
const ProviderPage = dynamic(() => import('@components/pages/ProviderPage'));
const StoryPage = dynamic(() => import('@components/pages/StoryPage'));
const TwoColumnLeftRailPage = dynamic(
  () => import('@components/pages/TwoColumnLeftRailPage')
);
const TwoColumnPage = dynamic(() => import('@components/pages/TwoColumnPage'));
const TwoColumnRightRailPage = dynamic(
  () => import('@components/pages/TwoColumnRightRailPage')
);
const SEMPage = dynamic(() => import('@components/pages/SEM'));

// Components
const AvatarTiles = dynamic(() => import('@components/AvatarTiles'));
const BannerCallOut = dynamic(
  () => import('@components/Banners/BannerCallOut')
);
const BannerDivider = dynamic(
  () => import('@components/Banners/BannerDivider')
);
const BarChart = dynamic(() => import('@components/Charts/BarChart'));

const Bylines = dynamic(() => import('@components/Bylines/Bylines'));

const BrowseStoriesByTopic = dynamic(
  () => import('@components/Story/BrowseStoriesByTopic/BrowseStoriesByTopic')
);
const AuthorAccordion = dynamic(
  () => import('@components/AuthorAccordion/AuthorAccordion')
);

const CallToAction = dynamic(() => import('@components/CallToAction'));
const CTA = dynamic(() => import('@components/CTA'));
const Carousel = dynamic(() => import('@components/Carousel'));
const CollapsibleSection = dynamic(
  () => import('@components/CollapsibleSection')
);
const Columns = dynamic(() => import('@components/LayoutStructure/Columns'));
const CustomTableOfContents = dynamic(
  () => import('@components/TableOfContents/CustomTableOfContents')
);
const DualTestimonial = dynamic(
  () => import('@components/DualTestimonial/DualTestimonial')
);
const EnhancedSearch = dynamic(
  () => import('@components/Search/EnhancedSearch')
);
const Expander = dynamic(() => import('@components/LayoutStructure/Expander'));
const ExperimentViewed = dynamic(
  () => import('@components/Plasmic/ExperimentViewed/ExperimentViewed')
);
const ExpertContributors = dynamic(() => import('./ExpertContributors'));
const FacetedSearch = dynamic(() => import('@components/FacetedSearch'));
const FAQ = dynamic(() => import('@components/FAQ'));
const FeaturedCards = dynamic(() => import('@components/FeaturedCards'));
const FeaturedProviders = dynamic(
  () => import('@components/FeaturedProviders')
);
const Features = dynamic(() => import('@components/Features'));
const FeaturesMini = dynamic(() => import('@components/FeaturesMini'));

const GeoIntro = dynamic(() => import('@components/GeoIntro'));
const GuidedSearch = dynamic(() => import('@components/GuidedSearch'));
const Heading = dynamic(() => import('@components/Heading'));
const Hero = dynamic(() => import('@components/Hero'));
const Image = dynamic(() => import('@components/Image/Image'));
const ImageTestimonial = dynamic(
  () => import('@components/ImageTestimonial/ImageTestimonial')
);

const InfoRequestSection = dynamic(
  () => import('@components/InfoRequestSection')
);
const InquiryForm = dynamic(() => import('@components/InquiryForm'));
const InterlinkingCitiesByCounty = dynamic(
  () => import('@components/InterlinkingCitiesByCounty')
);
const InterlinkingGeosByState = dynamic(
  () => import('@components/InterlinkingGeosByState')
);
const InterlinkingLegacyGeos = dynamic(
  () => import('@components/InterlinkingLegacyGeos')
);
const InterlinkingModule = dynamic(
  () => import('@components/InterlinkingModule')
);
const InterlinkingPopularLocales = dynamic(
  () => import('@components/InterlinkingPopularLocales')
);
const InterlinkingStatesByCareType = dynamic(
  () => import('@components/InterlinkingStatesByCareType')
);
const Item = dynamic(() => import('@components/List/Item'));
const List = dynamic(() => import('@components/List'));
const Map = dynamic(() => import('@components/Map'));
const MedicareReviews = dynamic(() => import('@components/MedicareReviews'));
const MiniSelfSearch = dynamic(() => import('@components/MiniSelfSearch'));
const MultiStepForm = dynamic(() => import('@components/MultiStepForm'));
const NearbyCare = dynamic(() => import('@components/NearbyCare'));
const NearbyGeoPages = dynamic(() => import('@components/NearbyGeoPages'));
const OfferingListing = dynamic(() => import('@components/OfferingListing'));
const PageTitleBanner = dynamic(() => import('@components/PageTitleBanner'));
const Paragraph = dynamic(() => import('@components/Paragraph'));
const PressReleaseLinks = dynamic(
  () => import('@components/PressReleaseLinks')
);
const ProductOverviewCard = dynamic(
  () => import('@components/ProductOverviewCard')
);
const ProductReviewCard = dynamic(
  () => import('@components/ProductReviewCard')
);
const ProsAndCons = dynamic(() => import('@components/ProsAndCons'));
const MedicalAlertAd = dynamic(() => import('@components/MedicalAlertAd'));
const DownloadPdfBlock = dynamic(() => import('@components/DownloadPdfBlock'));
const MobileHelp = dynamic(() => import('@components/MobileHelp'));
const ProviderCareTypes = dynamic(
  () => import('@components/ProviderCareTypes')
);
const ProviderImage = dynamic(
  () => import('@components/ProviderImage/ProviderImage')
);
const ProviderImagesGallery = dynamic(
  () => import('@components/ProviderImagesGallery/ProviderImagesGallery')
);
const RelatedArticles = dynamic(
  () => import('@components/RelatedArticle/RelatedArticle')
);
const RelatedStories = dynamic(
  () => import('@components/Story/RelatedStories')
);
const Reviews = dynamic(
  () => import('@components/ProviderDetailsReviews/Reviews')
);
const ReviewsSidebar = dynamic(() => import('@components/ReviewsSidebar'));
const ReviewSummary = dynamic(() => import('@components/ReviewSummary'));
const Search = dynamic(() => import('@components/Search/Search'));
const SectionChart = dynamic(() => import('@components/Sections/SectionChart'));
const SectionImage = dynamic(() => import('@components/Sections/SectionImage'));
const SectionTable = dynamic(() => import('@components/Sections/SectionTable'));
const SectionTitle = dynamic(() => import('@components/Sections/SectionTitle'));
const SitemapCareType = dynamic(
  () => import('@components/Sitemap/SitemapCareType')
);
const SitemapIndex = dynamic(() => import('@components/Sitemap/SitemapIndex'));
const SitemapState = dynamic(() => import('@components/Sitemap/SitemapState'));
const Spacing = dynamic(() => import('@components/LayoutStructure/Spacing'));
const Stats = dynamic(() => import('@components/Stats'));
const Story = dynamic(() => import('@components/Story/Story'));
const StoryImage = dynamic(() => import('@components/Image/StoryImage'));
const SubscriptionProviderCTA = dynamic(
  () => import('@components/SubscriptionProviderCTA/SubscriptionProviderCTA')
);
const Test = dynamic(() => import('@components/Test'));
const Testimonial = dynamic(() => import('@components/Testimonial'));
const Text = dynamic(() => import('@components/Text/Text'));
const ThankYou = dynamic(() => import('@components/ThankYou'));
const ThankYouPage = dynamic(() => import('@components/ThankYouPage'));
const TopReviews = dynamic(
  () => import('@components/ProviderDetailsReviews/TopReviews')
);
const VerticalTableOfContents = dynamic(
  () => import('@components/TableOfContents/VerticalTableOfContents')
);
const VideoEmbed = dynamic(() => import('@components/VideoEmbed'));
const CommunityComparison = dynamic(
  () => import('@components/CommunityComparison')
);
const HighlightBlock = dynamic(() => import('@components/HighlightBlock'));
const ReviewsCarousel = dynamic(() => import('@components/ReviewsCarousel'));
const ContactInfo = dynamic(() => import('@components/ContactInfo'));

// Account
const CreateReviewButton = dynamic(
  () => import('@components/Account/Reviews/CreateReviewButton')
);
const ReviewContest = dynamic(
  () => import('@components/ReviewForms/ReviewContest')
);
const InteractiveStatesMap = dynamic(
  () => import('@components/InteractiveStatesMap')
);
const ReviewResponse = dynamic(
  () => import('@components/ReviewForms/ReviewResponse')
);
const RichText = dynamic(() => import('@components/RichText/RichText'));
const SingleTestimonial = dynamic(
  () => import('@components/SingleTestimonial')
);
const WhyCaring = dynamic(() => import('@components/WhyCaring/WhyCaring'));
const WhoAreYouSearchingForPrompt = dynamic(
  () =>
    import(
      '@components/WhoAreYouSearchingForPrompt/WhoAreYouSearchingForPrompt'
    )
);

// Cost Module
const CostByLocale = dynamic(
  () => import('@components/CostModule/CostByLocale')
);
const CostPerMonth = dynamic(
  () => import('@components/CostModule/CostPerMonth')
);
const CostComparisonByCareType = dynamic(
  () => import('@components/CostModule/CostComparisonByCareType')
);

export type PageTemplateKeys = keyof typeof templatePages;

const templatePages = {
  // Pages
  'spa-lm:pages/1column': OneColumnPage,
  'spa-lm:pages/2column-left-rail': TwoColumnLeftRailPage,
  'spa-lm:pages/2column-right-rail': TwoColumnRightRailPage,
  'spa-lm:pages/2column': TwoColumnPage,
  'spa-lm:pages/default': DefaultPage,
  'spa-lm:pages/geo': GeoPage,
  'spa-lm:pages/provider': ProviderPage,
  'spa-lm:pages/story': StoryPage,
  'spa-lm:pages/sem': SEMPage,
};

const config = {
  componentMappings: {
    ...templatePages,

    // Components
    'spa-lm:components/avatarTiles': AvatarTiles,
    'spa-lm:components/advertisingDisclosure': AdvertisingDisclosure,
    'spa-lm:components/authorAccordion': AuthorAccordion,
    'spa-lm:components/bannerCallOut': BannerCallOut,
    'spa-lm:components/bannerDivider': BannerDivider,
    'spa-lm:components/barChart': BarChart,
    'spa-lm:components/bylines': Bylines,
    'spa-lm:components/browseStoriesByTopic': BrowseStoriesByTopic,
    'spa-lm:components/image': Image,
    'spa-lm:components/imageTestimonial': ImageTestimonial,
    'spa-lm:components/callToAction': CallToAction,
    'spa-lm:components/cta': CTA,
    'spa-lm:components/carousel': Carousel,
    'spa-lm:components/collapsibleSection': CollapsibleSection,
    'spa-lm:components/columns': Columns,
    'spa-lm:components/columnsProvider': Columns,
    'spa-lm:components/enhancedSearch': EnhancedSearch,
    'spa-lm:components/expander': Expander,
    'spa-lm:components/experimentViewed': ExperimentViewed,
    'spa-lm:components/expertContributors': ExpertContributors,
    'spa-lm:components/dualTestimonial': DualTestimonial,
    'spa-lm:components/facetedSearch': FacetedSearch,
    'spa-lm:components/featuredCards': FeaturedCards,
    'spa-lm:components/featuredProviders': FeaturedProviders,
    'spa-lm:components/features': Features,
    'spa-lm:components/featuresMini': FeaturesMini,
    'spa-lm:components/geoIntro': GeoIntro,
    'spa-lm:components/guidedSearch': GuidedSearch,
    'spa-lm:components/heading': Heading,
    'spa-lm:components/hero': Hero,
    'spa-lm:components/infoRequestSection': InfoRequestSection,
    'spa-lm:components/inquiryForm': InquiryForm,
    'spa-lm:components/interlinkingCitiesByCounty': InterlinkingCitiesByCounty,
    'spa-lm:components/interlinkingGeosByState': InterlinkingGeosByState,
    'spa-lm:components/interlinkingLegacyGeos': InterlinkingLegacyGeos,
    'spa-lm:components/interlinkingModule': InterlinkingModule,
    'spa-lm:components/interlinkingPopularLocales': InterlinkingPopularLocales,
    'spa-lm:components/interlinkingStatesByCareType':
      InterlinkingStatesByCareType,
    'spa-lm:components/list': List,
    'spa-lm:components/listItem': Item,
    'spa-lm:components/map': Map,
    'spa-lm:components/marketingCTA': NewCallToAction,
    'spa-lm:components/callToActionBlocks': CallToActionBlocks,
    'spa-lm:components/medicareReviews': MedicareReviews,
    'spa-lm:components/miniSelfSearch': MiniSelfSearch,
    'spa-lm:components/multiStepForm': MultiStepForm,
    'spa-lm:components/nearbyCare': NearbyCare,
    'spa-lm:components/nearbyGeoPages': NearbyGeoPages,
    'spa-lm:components/offeringListing': OfferingListing,
    'spa-lm:components/pageTitleBanner': PageTitleBanner,
    'spa-lm:components/paragraph': Paragraph,
    'spa-lm:components/pressReleaseLinks': PressReleaseLinks,
    'spa-lm:components/productOverviewCard': ProductOverviewCard,
    'spa-lm:components/productReviewCard': ProductReviewCard,
    'spa-lm:components/prosAndCons': ProsAndCons,
    'spa-lm:components/medicalAlertAd': MedicalAlertAd,
    'spa-lm:components/downloadPdfBlock': DownloadPdfBlock,
    'spa-lm:components/mobileHelp': MobileHelp,
    'spa-lm:components/providerCareTypes': ProviderCareTypes,
    'spa-lm:components/providerImage': ProviderImage,
    'spa-lm:components/providerImagesGallery': ProviderImagesGallery,
    'spa-lm:components/relatedArticles': RelatedArticles,
    'spa-lm:components/reviews': Reviews,
    'spa-lm:components/reviewsSidebar': ReviewsSidebar,
    'spa-lm:components/reviewSummary': ReviewSummary,
    'spa-lm:components/search': Search,
    'spa-lm:components/sectionChart': SectionChart,
    'spa-lm:components/sectionFAQ': FAQ,
    'spa-lm:components/faq': FAQ,
    'spa-lm:components/sectionImage': SectionImage,
    'spa-lm:components/sectionTable': SectionTable,
    'spa-lm:components/sectionTitle': SectionTitle,
    'spa-lm:components/sitemapCareType': SitemapCareType,
    'spa-lm:components/sitemapIndex': SitemapIndex,
    'spa-lm:components/sitemapState': SitemapState,
    'spa-lm:components/spacing': Spacing,
    'spa-lm:components/stats': Stats,
    'spa-lm:components/subscriptionProviderCta': SubscriptionProviderCTA,
    'spa-lm:components/test': Test,
    'spa-lm:components/testimonial': Testimonial,
    'spa-lm:components/thankYou': ThankYou,
    'spa-lm:components/thankYouPage': ThankYouPage,
    'spa-lm:components/topReviews': TopReviews,
    'spa-lm:components/videoEmbed': VideoEmbed,
    'spa-lm:components/interactiveStatesMap': InteractiveStatesMap,
    'spa-lm:components/whoAreYouSearchingForPrompt':
      WhoAreYouSearchingForPrompt,
    'spa-lm:components/singleTestimonial': SingleTestimonial,
    'spa-lm:components/communityComparison': CommunityComparison,
    'spa-lm:components/highlightBlock': HighlightBlock,
    'spa-lm:components/reviewsCarousel': ReviewsCarousel,
    'spa-lm:components/contactInfo': ContactInfo,
    'spa-lm:components/costByLocale': CostByLocale,
    'spa-lm:components/costPerMonth': CostPerMonth,
    'spa-lm:components/costComparisonByCareType': CostComparisonByCareType,

    // Reviews
    'spa-lm:components/reviewContest': ReviewContest,
    'spa-lm:components/reviewResponse': ReviewResponse,
    'spa-lm:components/reviewSubmissionsNew': ReviewSubmissionsNew,

    // Accounts
    'accounts-lm:components/writeAReview': CreateReviewButton,

    //ToC
    'spa-lm:components/customTableOfContents': CustomTableOfContents,
    'spa-lm:components/tableOfContents': VerticalTableOfContents,

    // Stories
    'spa-lm:components/relatedStories': RelatedStories,
    'spa-lm:components/richText': RichText,
    'spa-lm:components/story': Story,
    'spa-lm:components/storyImage': StoryImage,
    'spa-lm:components/text': Text,
    'spa-lm:components/whyCaring': WhyCaring,
  },
};

// // Wrap all components with error handler hoc
// const keys = Object.keys(config.componentMappings);
// keys.forEach((key) => {
//   config.componentMappings[key] = () =>
//     wrapWithErrorHandler(config.componentMappings[key]);
// });

export default config;
