import { JsonLd } from '@components/JsonLd/JsonLd';
import { ParsedUrlQuery } from 'querystring';
import { Provider } from '~/contexts/Provider';
import { findSiteForHost, SiteDefinition } from '~/contexts/SiteContext';
import { Domains } from '~/types/Domains';

interface JsonLdLocalBusinessProviderAndReviewsProps {
  canonical?: string;
  provider: Provider;
  context: {
    req?: {
      headers?: {
        host: string | undefined;
      };
      url?: string;
    };
    params: ParsedUrlQuery;
  };
}

interface buildProviderUrlProps {
  site: SiteDefinition;
  provider: Provider;
}

function buildProviderUrl({ site, provider }: buildProviderUrlProps): string {
  switch (site.path) {
    case Domains.CaringDomains.LIVE:
      return `https://www.${Domains.CaringDomains.LIVE}/${provider.rollup?.urlName}/${provider.state?.urlName}/${provider.city?.urlName}/${provider.slug}`;
    case Domains.SeniorHomesDomains.LIVE:
      return `https://www.${Domains.SeniorHomesDomains.LIVE}/${provider.state?.urlName}/${provider.city?.urlName}/${provider.slug}`;

    default:
      return '';
  }
}

export function JsonLdLocalBusinessProviderAndReviews({
  canonical = '',
  provider,
  context,
}: JsonLdLocalBusinessProviderAndReviewsProps) {
  const site = findSiteForHost(context.req?.headers?.host || '');
  const providerUrl = buildProviderUrl({ site, provider });
  const reviewSchema = provider.reviews?.map((review) => {
    const reviewData = {
      '@context': 'https://schema.org',
      '@type': 'Review',
      datePublished: review.createdAt,
      headline: review.title,
      reviewBody: review.content,
      author: {
        '@type': 'Person',
        name: review.authorName,
      },
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.rating,
        worstRating: 1,
        bestRating: 5,
      },
    };
    return reviewData;
  });

  let images = {};
  if (provider.photos?.[0]?.url) {
    images = {
      logo: provider.photos[0]?.url,
      image: provider.photos[0]?.url,
    };
  }

  let aggregateRating = {};
  if (provider.reviews && provider.reviews?.length > 0) {
    aggregateRating = {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: provider.averageRating,
        reviewCount: provider.reviews?.length ? provider.reviews?.length : 0,
      },
    };
  }

  const localBusiness = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: provider.name,
    ...images,
    url: `${providerUrl}`,
    priceRange: `$$`,
    address: {
      '@type': 'PostalAddress',
      addressLocality: provider?.address?.city,
      addressRegion: provider?.address?.state,
      postalCode: provider?.address?.zipCode,
      streetAddress: provider?.address?.street,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: provider?.address?.latitude,
      longitude: provider?.address?.longitude,
    },
    review: reviewSchema,
    ...aggregateRating,
  };

  return (
    <JsonLd
      key={provider.id}
      scriptKey={provider?.id ? provider.id : ''}
      {...localBusiness}
    />
  );
}
